import React from 'react';
import { useTranslation } from 'react-i18next';
import { FONT_SIZE, Text, TEXT_COLOR } from '../../../UI-elements/Text';

const SearchProductPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARK_GREY}>
      {t('entries.search.resultsPlaceholder', { defaultValue: 'Please try a search term above.' })}
    </Text>
  );
};

export { SearchProductPlaceholder };
