import loadable, { LoadableComponent } from '@loadable/component';
import cn from 'classnames';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../../global/custom-hooks/useCart';
import { useCartSummaryToggleAccordion } from '../../global/custom-hooks/useCartSummaryToggleAccordion';
import { useGetBaseGAEventData } from '../../global/custom-hooks/useGetBaseGAEventData';
import { ViewCartTracking } from '../../global/google-analytics';
import { getCartToggle, getIsCartLoading } from '../../global/selectors/cart/selectors';
import { Drawer } from '../shared/Drawer';
import { setCartToggle } from './actions';
import styles from './cart.module.scss';

const CartContentWrapper: LoadableComponent<{
  closeCart: () => void;
}> = loadable(() =>
  import('./components/CartContentWrapper').then((module) => ({
    default: module.CartContentWrapper
  }))
);

const UnmemoizedCartComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCartOpen = useSelector(getCartToggle);
  const listOfSkusLoading = useSelector(getIsCartLoading);
  const { selectedCart } = useCart();
  const gaEventData = useGetBaseGAEventData();
  const closeCart = () => {
    setIsSummaryVisible(false);
    dispatch(setCartToggle(false));
  };
  const { setIsSummaryVisible } = useCartSummaryToggleAccordion();

  useEffect(() => {
    if (isCartOpen) {
      TagManager.dataLayer(
        ViewCartTracking({
          ...gaEventData,
          products: selectedCart?.items.concat(selectedCart?.freeItems || []),
          cartTotal: selectedCart?.total
        })
      );
    } else {
      closeCart();
    }
  }, [isCartOpen]);

  return (
    <>
      <Drawer
        dataTestId="cart"
        isOpen={isCartOpen}
        onClose={closeCart}
        className={cn({
          [styles['wrapper--blackout']]: listOfSkusLoading
        })}
        title={t('entries.cart.title', { defaultValue: 'Your cart' })}
      >
        <CartContentWrapper closeCart={closeCart} />
      </Drawer>
    </>
  );
};

const CartComponent = React.memo(UnmemoizedCartComponent);
export { CartComponent };
