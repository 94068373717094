import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CatalogPayload } from '../../../services/products/types';
import { FONT_SIZE, Text, TEXT_COLOR } from '../../../UI-elements/Text';

interface SearchProductMorePlaceholderProps {
  searchResults: CatalogPayload | undefined;
  count: number;
}
const SearchProductMorePlaceholder = ({ searchResults, count }: SearchProductMorePlaceholderProps) => {
  const { t } = useTranslation();
  return (
    <Text size={FONT_SIZE.TINY} color={TEXT_COLOR.DARK_GREY}>
      {searchResults?.page?.totalElements > 10 ? (
        <Trans t={t} i18nKey="entries.search.moreThan10ProductsFound">
          More than 10 products have been found
        </Trans>
      ) : (
        <Trans t={t} i18nKey="entries.search.totalResults" count={searchResults?.page?.totalElements}>
          <strong>{{ count }} result</strong> found
        </Trans>
      )}
    </Text>
  );
};

export { SearchProductMorePlaceholder };
